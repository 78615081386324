/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    clouds: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M16 7.5a2.5 2.5 0 01-1.456 2.272 3.5 3.5 0 00-.65-.824 1.5 1.5 0 00-.789-2.896.5.5 0 01-.627-.421 3 3 0 00-5.22-1.625 5.6 5.6 0 00-1.276.088 4.002 4.002 0 017.392.91A2.5 2.5 0 0116 7.5"/><path pid="1" d="M7 5a4.5 4.5 0 014.473 4h.027a2.5 2.5 0 010 5H3a3 3 0 01-.247-5.99A4.5 4.5 0 017 5m3.5 4.5a3.5 3.5 0 00-6.89-.873.5.5 0 01-.51.375A2 2 0 103 13h8.5a1.5 1.5 0 10-.376-2.953.5.5 0 01-.624-.492z"/>',
    },
});
